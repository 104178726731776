import { React, useState, useEffect } from "react";
import { BsFuelPumpFill } from "react-icons/bs";
import { MdMergeType } from "react-icons/md";

import { Link } from "react-router-dom";
import config from "../config";
import axios from "axios";

const Cars = () => {
  const [cars, setCars] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${config.endpoint}/carsData`);
        setCars(res.data);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div>
        {/* Header Banner */}
        <section
          className="banner-header section-padding bg-img"
          data-overlay-dark={5}
          data-background="assets/img/slider/3.jpg"
        >
          <div className="v-middle">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h6>Select Your Car</h6>
                  <h1>
                    Luxury <span>Car Fleet</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* divider line */}
        <div className="line-vr-section" />
        {/* Cars */}
        <section className="cars1 section-padding">
          <div className="container">
            <div className="row">
              {cars.map((car, index) => (
                <div className="col-lg-6 col-md-12 mb-60 " key={car._id}>
                  <div className="item">
                    <div className="img">
                      {" "}
                      <img src={car.heroImg[0].secure_url} alt />{" "}
                    </div>
                    <div className="con active">
                      <div className="row">
                        <div className="col-md-7">
                          <div className="title">
                            <Link to="#">{car.carBrand}</Link>
                          </div>
                          <div className="details">
                            <span>
                              <MdMergeType color="white"  size={20}/>
                              Model {car.carModel}
                            </span>{" "}
                            <span>
                              <BsFuelPumpFill color="white" /> {car.carFuelType}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="book">
                            <div>
                              <Link to={`/${car._id}`} className="btn">
                                <span>Details</span>
                              </Link>
                              {/* <Link to="/details"className="btn">
                                <span>Details</span>
                              </Link> */}
                            </div>
                            <div>
                              <span className="price">{car.carPrice}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Footer */}
        <footer className="footer clearfix">
          <div className="container">
            {/* first footer */}
            <div className="first-footer">
              <div className="row">
                <div className="col-md-12">
                  <div className="links dark footer-contact-links">
                    <div className="footer-contact-links-wrapper">
                      <div className="footer-contact-link-wrapper">
                        <div className="image-wrapper footer-contact-link-icon">
                          <div className="icon-footer">
                            {" "}
                            <i className="flaticon-phone-call" />{" "}
                          </div>
                        </div>
                        <div className="footer-contact-link-content">
                          <h6>Call us</h6>
                          <p>+91 90509-18482</p>
                        </div>
                      </div>
                      <div className="footer-contact-links-divider" />
                      <div className="footer-contact-link-wrapper">
                        <div className="image-wrapper footer-contact-link-icon">
                          <div className="icon-footer">
                            {" "}
                            <i className="omfi-envelope" />{" "}
                          </div>
                        </div>
                        <div className="footer-contact-link-content">
                          <h6>Write to us</h6>
                          <p>info@Ganesh.com</p>
                        </div>
                      </div>
                      <div className="footer-contact-links-divider" />
                      <div className="footer-contact-link-wrapper">
                        <div className="image-wrapper footer-contact-link-icon">
                          <div className="icon-footer">
                            {" "}
                            <i className="omfi-location" />{" "}
                          </div>
                        </div>
                        <div className="footer-contact-link-content">
                          <h6>Address</h6>
                          <p>
                            Pipli Rd, opposite chatha complex, Sector 7,
                            Kurukshetra, Haryana 136118
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* second footer */}
            <div className="second-footer">
              <div className="row">
                {/* about & social icons */}
                <div className="col-md-4 widget-area">
                  <div className="widget clearfix">
                    <div className="footer-logo">
                      <img src="assets/img/logo-light.png" alt />
                    </div>
                    {/* <div class="footer-logo"><h2>CARE<span>X</span></h2></div> */}
                    <div className="widget-text">
                      <p>
                        Select your car online, we inspect and deliver it to you
                        hassle-free. Enjoy convenience and peace of mind with
                        our service.
                      </p>
                      <div className="social-icons">
                        <ul className="list-inline">
                          <li>
                            <Link to="#">
                              <i className="fa-brands fa-whatsapp" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="fa-brands fa-facebook-f" />
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <i className="fa-brands fa-youtube" />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* quick links */}
                <div className="col-md-3 offset-md-1 widget-area">
                  <div className="widget clearfix usful-links">
                    <h3 className="widget-title">Quick Links</h3>
                    <ul>
                      <li>
                        <Link to="/">About</Link>
                      </li>
                      <li>
                        <Link to="/cars">Cars</Link>
                      </li>
                      <li>
                        <Link to="/">Team</Link>
                      </li>
                      <li>
                        <Link to="/">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* subscribe */}
                <div className="col-md-4 widget-area">
                  <div className="widget clearfix">
                    <h3 className="widget-title">Subscribe</h3>
                    <p>Selling a car? We’re buying</p>
                    <div className="widget-newsletter">
                      <form action="#">
                        <input
                          type="email"
                          placeholder="Email Address"
                          required
                        />
                        <button type="submit">
                          <i className="ti-arrow-top-right" />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* bottom footer */}
            <div className="bottom-footer-text">
              <div className="row copyright">
                <div className="col-md-12">
                  <p className="mb-0">
                    ©2024{" "}
                    <Link to="https://cybersolvings.com/">Cybersolvings</Link>.
                    All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* jQuery */}
      </div>
    </div>
  );
};

export default Cars;

// import React from "react";
// import { useCarContext } from "../context/CarContext";

// const AnotherPage = () => {
//   const { selectedCar } = useCarContext();
//   console.log("🚀 ~ AnotherPage ~ selectedCar:", selectedCar)

//   return (
//     <div>
//       {selectedCar ? (
//         <div>
//           <h2>Selected Car Details:</h2>
//           <p>Brand: {selectedCar.carBrand}</p>
//           <p>Model: {selectedCar.carModel}</p>
//           {/* Add more details here */}
//         </div>
//       ) : (
//         <p>No car selected.</p>
//       )}
//     </div>
//   );
// };

// export default AnotherPage;
