import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./SliderCompo.css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Scrollbar, A11y } from "swiper/modules";
import axios from "axios";
import config from "../../config";

const SliderCompo = () => {
  const [cars, setCars] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${config.endpoint}/carsData`);
        setCars(res.data);
      } catch (err) {
        console.error("Error fetching data:", err);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="top-div">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={50}
          navigation
          autoplay
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
        >
          {cars.map((car, index) => (
            <SwiperSlide key={car._id}>
              <a href="/cars">
                <div className="it-services-card">
                  <img
                    src={car.heroImg[0].secure_url}
                    alt={car.model}
                    className="it-services-card-image"
                  />
                  <div className="it-services-card-content">
                    <h2 className="it-services-card-title">
                      <button className="academy-button ">
                        
                        <div className="academy-button-text">
                        Brand &nbsp;
                          {car.carBrand}{" "}
                          
                        </div>{" "}

                        <div className="academy-button-text">
                          {" "}
                        Model &nbsp;
                          {car.carModel}{" "}
                        </div>{" "}

                        <div className="academy-button-text">
                        KMS &nbsp;
                          {car.carKms}{" "}
                          
                        </div>{" "}

                        <div className="academy-button-text">
                        Owner &nbsp;
                          {car.carWoner}{" "}
                          
                        </div>{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 5l7 7-7 7"
                          ></path>
                        </svg>
                      </button>
                    </h2>
                  </div>
                </div>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default SliderCompo;
