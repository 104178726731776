const config = {
  endpoint: `http://localhost:3004/v1`,

  endpoint: `https://ganesh-car-admin.onrender.com/v1`,


  // endpoint: `https://car-x78k.onrender.com/v1`,
};

export default config;
