import Header from "./components/Header";
import Cars from "./pages/Cars";
import Details from "./pages/Details";
import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import CarDetailsPage from "./pages/CarDetailsPage";
import { useNavigate } from "react-router-dom";

function App() {
  

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cars" element={<Cars />} />
        <Route path="/:carId" element={<Details />} />
        <Route path="/carss" element={<CarDetailsPage />} />
      </Routes>
    </div>
  );
}

export default App;
