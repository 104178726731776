import React from "react";
import { Link } from "react-router-dom";
const Header = () => {
  const homeBtn = () => {
    window.location.href = "/";
  };

  const handleLogo = () => {
    window.location.href = "/";
  };
  return (
    <div>
      <div>
        {/* Preloader */}

        {/* Navbar */}
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            {/* Logo */}
            <div className="logo-wrapper">
              <Link className="logo" to="/" onClick={handleLogo}>
                {" "}
                <img
                  src="assets/img/logo-light.png"
                  className="logo-img"
                  alt
                />{" "}
              </Link>
              {/* <a class="logo" href="index.html"><h2>Renta<span>x</span></h2></a> */}
            </div>
            {/* Button */}
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbar"
              aria-controls="navbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {" "}
              <span className="navbar-toggler-icon">
                <i className="fa-solid fa-bars" />
              </span>{" "}
            </button>
            {/* Menu */}
            <div className="collapse navbar-collapse" id="navbar">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  {" "}
                  <Link className="nav-link active" to="/" onClick={homeBtn}>
                    Home{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    to="/"
                    onClick={homeBtn}
                    href="#about"
                  >
                    About
                  </Link>
                  {/* <a className="nav-link" href="#about">
                    About
                  </a> */}
                </li>
                <li>
                  <a href="/cars" className="nav-link">
                    Cars
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    Team
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    Contact
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    Faq
                  </a>
                </li>
              </ul>
              <div className="navbar-right">
                <div className="wrap">
                  <div className="icon">
                    {" "}
                    <i className="flaticon-phone-call" />{" "}
                  </div>
                  <div className="text">
                    <p>Need help?</p>
                    <h5>
                      <Link to="tel:9050918482">9050918482</Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* Slider */}
      </div>
    </div>
  );
};

export default Header;
